import React from 'react'
import { createContext } from 'react'
import { Content } from 'interfaces/Pipelines'
import { IAddPipelinesContext } from './types'
import { DataGroup } from 'shared/components/SubGroupTextFieldsContainer/types'
import { GridSelectionModel } from '@mui/x-data-grid'
import { LayoutFile } from 'shared/components/ParameterForm/types'
export const AddPipelineContext = createContext<IAddPipelinesContext>(
  {} as IAddPipelinesContext
)

export const AddPipelinesProvider = ({ initialState, children }) => {
  const [pipelineArray, setPipelineArray] =
    React.useState<Content[]>(initialState)
  const [pipelineOldArray, setPipelineOldArray] =
    React.useState<Content[]>(initialState)
  const [pipelineCheck, setPipelineCheck] = React.useState<GridSelectionModel>(
    []
  )
  const [fileLayoutEdit, setFileLayoutEdit] = React.useState<LayoutFile>()
  const [flagEdit, setFlagEdit] = React.useState<boolean>(false)
  const [isPostPipeline, setIsPostPipeline] = React.useState<boolean>(false)
  const [idPipelineRun, setIdPipelineRun] = React.useState<string>('')
  const [flagFileEdit, setFlagFileEdit] = React.useState<boolean>(false)
  const [subGroupId, setSubGroupId] = React.useState<string>('')
  const [idScheduledRun, setIdScheduledRun] = React.useState<string>('')
  const [runId, setRunId] = React.useState<string>('')
  const [pipelineId, setPipelineId] = React.useState<string>('')
  const [dateEditScheduledRun, setDateScheduledRun] = React.useState<Date>(
    new Date()
  )
  const [dataInput, setDataInput] = React.useState<DataGroup>({
    name: '',
    description: '',
    cargoIdentifier: false
  })
  const [tab, setTab] = React.useState<string>('')
  const onChange = (row: Content[]) => {
    setPipelineArray(row)
  }
  const onChangeFlagEdit = (flag: boolean) => {
    setFlagEdit(flag)
  }
  const onChangeIsPostPipeline = (flag: boolean) => {
    setIsPostPipeline(flag)
  }
  const onChangeFlagFileEdit = (flag: boolean) => {
    setFlagFileEdit(flag)
  }
  const onChangeDateScheduledRun = (value: Date) => {
    setDateScheduledRun(value)
  }
  const onChangeTab = (value: string) => {
    setTab(value)
  }
  const onChangeAddLayoutFile = (layoutFile: LayoutFile) => {
    setFileLayoutEdit(layoutFile)
  }
  const onChangeRunId = (value: string) => {
    setRunId(value)
  }
  const onChangeIdPipelineRun = (value: string) => {
    setIdPipelineRun(value)
  }
  const onChangePipelineId = (value: string) => {
    setPipelineId(value)
  }
  const onChangeIdScheduledRun = (value: string) => {
    setIdScheduledRun(value)
  }
  const onChangeSubGroupId = (id: string) => {
    setSubGroupId(id)
  }
  const onChangeOldPipelineArray = (row: Content[]) => {
    setPipelineOldArray(row)
  }
  const onChangePipelineCheck = (row: GridSelectionModel) => {
    setPipelineCheck(row)
  }

  const onChangeDelete = (id: string) => {
    setPipelineArray((item) => [
      ...item.filter((currentRow) => currentRow.id !== id)
    ])
  }

  const onChangeDataInput = (row: DataGroup) => {
    setDataInput(row)
  }

  return (
    <AddPipelineContext.Provider
      value={{
        pipelineArray,
        dataInput,
        flagEdit,
        isPostPipeline,
        subGroupId,
        pipelineCheck,
        idScheduledRun: idScheduledRun,
        pipelineOldArray,
        tab,
        runId,
        pipelineId,
        idPipelineRun,
        fileLayoutEdit,
        flagFileEdit,
        dateEditScheduledRun: dateEditScheduledRun,
        onChangeTab,
        onChange,
        onChangeIdPipelineRun,
        onChangeRunId,
        onChangeIsPostPipeline,
        onChangeAddLayoutFile,
        onChangeFlagFileEdit,
        onChangePipelineId,
        onChangeDateScheduledRun: onChangeDateScheduledRun,
        onChangeFlagEdit,
        onChangeIdScheduledRun: onChangeIdScheduledRun,
        onChangeSubGroupId,
        onChangeDelete,
        onChangeDataInput,
        onChangeOldPipelineArray,
        onChangePipelineCheck
      }}
    >
      {children}
    </AddPipelineContext.Provider>
  )
}
