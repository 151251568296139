import { useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material/'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useAuth } from 'shared/hooks/auth/useAuth'
import { IMenuItemProps, IItemProps } from './types'

import { colors } from 'shared/styles/colors'

const Item = ({
  route,
  component,
  handleClick,
  children,
  endAdornment
}: IItemProps) => {
  const { pathname } = useLocation()
  // TODO: mapear interface de props extras
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const extraProps = {} as any

  if (!children) {
    extraProps.to = route.path
    extraProps.component = component
  }

  const isSelected = route.path === pathname

  return (
    <>
      <ListItem
        selected={isSelected}
        onClick={handleClick}
        key={route.path}
        {...extraProps}
        sx={{
          color: colors.lightGray,
          '&:hover': {
            backgroundColor: colors.overlayHover
          },
          '&.Mui-selected': {
            color: colors.secondary03
          }
        }}
      >
        <Box marginRight={1}>{route.icon}</Box>
        <ListItemText
          primary={
            <Typography
              sx={{ color: isSelected ? colors.secondary03 : '#383838' }}
            >
              {route.meta.title}
            </Typography>
          }
        />
        {endAdornment}
      </ListItem>
      {children}
    </>
  )
}

const MenuItem = ({ route, component, toggleDrawer }: IMenuItemProps) => {
  const [open, setOpen] = useState<boolean>(false)
  const { checkPermissions } = useAuth()
  const hasSubItens = route.children && route.children?.length

  const handleClick = useCallback(() => {
    setOpen((o) => !o)
  }, [])

  if (hasSubItens) {
    return (
      <Box
        sx={{
          cursor: 'pointer'
        }}
      >
        <Item
          endAdornment={open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          route={route}
          handleClick={handleClick}
          component={component}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {route.children?.map((item) => {
                return (
                  <div
                    onClick={toggleDrawer(false)}
                    onKeyDown={toggleDrawer(false)}
                    role="presentation"
                    key={item.path}
                  >
                    <Item route={item} component={component} isChildren />
                  </div>
                )
              })}
            </List>
          </Collapse>
        </Item>
      </Box>
    )
  }

  return (
    <>
      {route.meta.profilesAuthorized ? (
        checkPermissions(
          route.meta?.profilesAuthorized ? route.meta?.profilesAuthorized : []
        ) && (
          <div
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
            role="presentation"
          >
            <Item route={route} component={component} />
          </div>
        )
      ) : (
        <div
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
          role="presentation"
        >
          <Item route={route} component={component} />
        </div>
      )}
    </>
  )
}

export default MenuItem
