import { Box, CircularProgress } from '@mui/material'
import React from 'react'

type Props = {
  color?: 'primary' | 'error' | 'success'
  height?: number
  isLoading: boolean
  size?: string
}

const Loading: React.FC<Props> = ({
  color = 'primary',
  children,
  height = 120,
  isLoading,
  size = '3rem'
}) => {
  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: `${height}px`
        }}
      >
        <CircularProgress size={size} color={color} />
      </Box>
    )
  }

  return <>{children}</>
}

export default Loading
