import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageBrowserDetector from 'i18next-browser-languagedetector'
import Backend from 'i18next-http-backend'
import namespaces from './namespaces'

i18n
  .use(Backend)
  .use(LanguageBrowserDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    defaultNS: namespaces.defaultNs,
    ns: namespaces.NSs,
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json`
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    }
  })

export default i18n
