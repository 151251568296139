import axios from 'axios'

const authApi = axios.create({
  baseURL: `${process.env.REACT_APP_AUTH_URL}/api`
})

authApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
)

export default authApi
