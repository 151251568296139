/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {
  BrowserCacheLocation,
  Configuration,
  PublicClientApplication
} from '@azure/msal-browser'

export const msalConfig: Configuration = {
  auth: {
    clientId: process.env.REACT_APP_AZURE_AD_CLIENT_ID!,
    authority: `https://login.microsoftonline.com/${process.env.REACT_APP_AZURE_AD_TENANT_ID}`,
    redirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI!,
    postLogoutRedirectUri: process.env.REACT_APP_AZURE_AD_REDIRECT_URI!
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false
  }
}

export const msalInstance = new PublicClientApplication(msalConfig)
