import axios from 'axios'

const api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}/api`
})

// Add a response interceptor
api.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    return Promise.reject(error)
  }
)

export default api
