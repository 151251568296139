type Meta = {
  hidden?: boolean
  title: string
  profilesAuthorized?: string[]
}

export interface IRoute {
  path?: string
  exact?: boolean
  meta: Meta
  component?: JSX.Element
  icon?: JSX.Element
  children?: IRoute[]
  menu?: boolean
}

export enum RoutePath {
  AllowedValueCreate = '/allowed-values',
  AllowedValueEdit = '/allowed-values/:id',
  ExecutionLoads = '/execution-loads',
  Home = '/',
  GroupRegistration = '/group-registration',
  GroupRegistrationEdit = '/group-registration/:id',
  ParameterCreate = '/parameters',
  ParameterEdit = '/pipelines/:idPipeline/parameters/:id',
  PipelineAddListUser = '/pipelines-add-list-user',
  PipelineParameterCreate = '/pipelines/:idPipeline/parameters',
  PipelineEdit = '/pipelines/:id',
  PipelineMap = '/pipelines/map',
  ProfileCreate = '/profiles',
  ProfileEdit = '/profiles/:id',
  RegistrationTable = '/registration-table',
  SubgroupCreate = '/subgroups',
  SubgroupEdit = '/subgroups/:id',
  UserRegistration = '/user-registration',
  UserRegistrationClone = '/user-registration-clone',
  UserRegistrationEmail = '/user-registration/:email',
  Management = '/management',
  FileLayoutEdit = '/file-layout-edit'
}
