import { lazy } from 'react'

export const Home = lazy(() => import('pages/home/Home'))
export const AddPipelines = lazy(() => import('pages/AddPipelines'))

export const AllowedValueCreate = lazy(() => import('pages/AllowedValueCreate'))
export const AllowedValueEdit = lazy(() => import('pages/AllowedValueEdit'))
export const ExecutionLoads = lazy(() => import('pages/ExecutionLoads'))
export const ParameterCreate = lazy(() => import('pages/ParameterCreate'))
export const ParameterEdit = lazy(() => import('pages/ParameterEdit'))
export const PipelineCreate = lazy(() => import('pages/PipelineCreate'))
export const PipelineEdit = lazy(() => import('pages/PipelineEdit'))
export const PipelineParameterCreate = lazy(
  () => import('pages/PipelineParameterCreate')
)
export const ProfileCreate = lazy(() => import('pages/ProfileCreate'))
export const ProfileEdit = lazy(() => import('pages/ProfileEdit'))
export const RegistrationGroup = lazy(() => import('pages/RegistrationGroup/'))
export const RegistrationSubGroup = lazy(
  () => import('pages/RegistrationSubGroup/')
)
export const RegistrationTable = lazy(() => import('pages/RegistrationTable'))
export const UserAddPipelines = lazy(() => import('pages/UserAddPipelines'))
export const UserEdit = lazy(() => import('pages/UserEdit'))
export const UserClone = lazy(() => import('pages/UserClone'))
export const UserRegistration = lazy(() => import('pages/UserRegistration'))
export const Management = lazy(() => import('pages/Management'))
export const FileLayoutEdit = lazy(() => import('pages/FileLayoutEdit'))

export const MapPipeline = lazy(() => import('pages/MapPipeline'))

const Pages = {
  Home,
  AddPipelines,
  AllowedValueCreate,
  AllowedValueEdit,
  ExecutionLoads,
  ParameterCreate,
  ParameterEdit,
  PipelineCreate,
  PipelineEdit,
  PipelineParameterCreate,
  ProfileCreate,
  ProfileEdit,
  Management,
  RegistrationGroup,
  RegistrationSubGroup,
  RegistrationTable,
  UserAddPipelines,
  UserEdit,
  UserClone,
  UserRegistration,
  FileLayoutEdit,
  MapPipeline
}

export default Pages
