import { VFC } from 'react'
import { Route, Routes, BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import IconSvg from 'shared/components/Icon'

import IconBox from 'assets/box.png'
import AppMenu from 'shared/layout'
import TitleDispatcher from 'shared/components/TitleDispatcher'
import { useAuth } from 'shared/hooks/auth/useAuth'
import commonLocale from 'shared/i18n/locales/common'

import { ExecutionLoads } from 'pages'

import { getRoutes } from './routesConfig'
import { RoutePath } from './types'

const AppRoutes: VFC = () => {
  const { t } = useTranslation(['common'])
  const routes = getRoutes(t)

  const { userGroups } = useAuth()

  if (userGroups && userGroups.length > 0) {
    userGroups.map(async (item) => {
      await routes.push({
        path: `${RoutePath.ExecutionLoads}/${item.id}`,
        component: <ExecutionLoads {...item} />,
        meta: {
          title: `${t(commonLocale.execution_loads.title)} - ${item.name}`
        },
        icon: <IconSvg img={IconBox} />
      })
    })
  }

  return (
    <BrowserRouter>
      <AppMenu routes={routes}>
        <Routes>
          {routes.map((route) => {
            return (
              route.component && (
                <Route
                  key={route.path}
                  path={route.path}
                  element={
                    <>
                      <TitleDispatcher title={route.meta.title} />
                      {route.component}
                    </>
                  }
                />
              )
            )
          })}
        </Routes>
      </AppMenu>
    </BrowserRouter>
  )
}

export default AppRoutes
