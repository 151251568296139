import { useContext, useEffect } from 'react'
import { TitleContext } from 'shared/layout'

type Props = {
  title: string
}

const TitleDispatcher: React.VFC<Props> = ({ title }) => {
  const setTitle = useContext(TitleContext)

  useEffect(() => {
    setTitle(title)
  }, [title])

  return null
}

export default TitleDispatcher
