import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useCallback,
  ReactNode
} from 'react'
import { Box, Container, Paper } from '@mui/material'

import TopContainer from 'shared/components/TopContainer'
import { Header } from 'shared/layout/header'
import { Menu } from 'shared/layout/menu'
import { useAuth } from 'shared/hooks/auth/useAuth'

import { IRoute } from 'routes/types'

interface AppMenuProps {
  children: NonNullable<ReactNode>
  routes: Array<IRoute>
}

export const TitleContext = createContext<Dispatch<SetStateAction<string>>>(
  () => null
)

const AppMenu = ({ children, routes }: AppMenuProps) => {
  const { signOut, account } = useAuth()

  const [openDrawer, setOpenDrawer] = useState<boolean>(false)
  const [title, setTitle] = useState<string>('')

  const onCloseDrawer = useCallback(() => {
    setOpenDrawer(false)
  }, [])

  const onClickMenuButton = useCallback(() => {
    setOpenDrawer(true)
  }, [])

  const toggleDrawer =
    (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as KeyboardEvent).key === 'Tab' ||
          (event as KeyboardEvent).key === 'Shift')
      ) {
        return
      }
      setOpenDrawer(open)
    }

  return (
    <>
      <Header
        username={account ? account.name : ''}
        onClickLogout={signOut}
        onClickMenuButton={onClickMenuButton}
      />
      <Box display="flex" role="presentation">
        <Menu
          routes={routes}
          openDrawer={openDrawer}
          onCloseDrawer={onCloseDrawer}
          toggleDrawer={toggleDrawer}
        />

        <Container
          maxWidth={false}
          sx={{
            marginTop: '100px',
            maxWidth: '90%'
          }}
        >
          <Paper sx={{ borderRadius: 2 }}>
            <TitleContext.Provider value={setTitle}>
              <TopContainer title={title} />
              {children}
            </TitleContext.Provider>
          </Paper>
        </Container>
      </Box>
    </>
  )
}

export default AppMenu
