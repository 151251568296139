import React from 'react'
import { createContext } from 'react'
import { Content } from 'interfaces/SubGroup'
import { IAddSubGroupContext } from './types'
import { DataGroup } from 'shared/components/GroupTextFieldsContainer/types'
import { GridSelectionModel } from '@mui/x-data-grid'

export const AddSubGroupContext = createContext<IAddSubGroupContext>(
  {} as IAddSubGroupContext
)

export const AddSubGroupProvider = ({ initialState, children }) => {
  const [subGroupArray, setSubGroupArray] =
    React.useState<Content[]>(initialState)
  const [subGroupOldArray, setSubGroupOldArray] =
    React.useState<Content[]>(initialState)
  const [subGroupCheck, setSubGroupCheck] = React.useState<GridSelectionModel>(
    []
  )
  const [flagEdit, setFlagEdit] = React.useState<boolean>(false)
  const [groupId, setGroupId] = React.useState<string>('')
  const [dataInput, setDataInput] = React.useState<DataGroup>({
    name: '',
    description: '',
    cargoIdentifier: false
  })

  const onChangeSubGroupCheck = (row: GridSelectionModel) => {
    setSubGroupCheck(row)
  }

  const onChange = (row: Content[]) => {
    setSubGroupArray(row)
  }
  const onChangeFlagEdit = (flag: boolean) => {
    setFlagEdit(flag)
  }
  const onChangeGroupId = (id: string) => {
    setGroupId(id)
  }

  const onChangeOldSubGroupArray = (row: Content[]) => {
    setSubGroupOldArray(row)
  }

  const onChangeDelete = (id: string) => {
    setSubGroupArray((item) => [
      ...item.filter((currentRow) => currentRow.id !== id)
    ])
  }

  const onChangeDataInput = (row: DataGroup) => {
    setDataInput(row)
  }

  return (
    <AddSubGroupContext.Provider
      value={{
        subGroupArray,
        dataInput,
        flagEdit,
        groupId,
        subGroupCheck,
        subGroupOldArray,
        onChange,
        onChangeFlagEdit,
        onChangeGroupId,
        onChangeOldSubGroupArray,
        onChangeDelete,
        onChangeDataInput,
        onChangeSubGroupCheck
      }}
    >
      {children}
    </AddSubGroupContext.Provider>
  )
}
