import { styled } from '@mui/material/styles'
type Props = {
  img: string
}

const StyledIcon = styled('img')({})

const IconSvg: React.VFC<Props> = ({ img }) => {
  return <StyledIcon src={img} />
}

export default IconSvg
