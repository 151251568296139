export const NS = 'common'

const getName = (name: string) => `${NS}:${name}`

export default {
  common_name: getName('common_name'),
  title_sub_group: getName('title_sub_group'),
  title_group: getName('title_group'),
  execution_window: getName('execution_window'),
  process_quantity: getName('process_quantity'),
  date_execution: getName('date_for_execution'),
  yes: getName('yes'),
  no: getName('no'),
  autocomplete: {
    open: getName('autocomplete.open'),
    loading: getName('autocomplete.loading'),
    clear: getName('autocomplete.clear'),
    close: getName('autocomplete.close'),
    noOptions: getName('autocomplete.noOptions')
  },
  exit: getName('exit'),
  form: {
    add: getName('form.add'),
    confirm: getName('form.confirm'),
    cancel: getName('form.cancel'),
    close: getName('form.close'),
    required: getName('form.required'),
    register: getName('form.register'),
    edit: getName('form.edit'),
    delete: getName('form.delete'),
    deleteMessage: getName('form.deleteMessage'),
    numeric: getName('form.numeric'),
    greater_than: getName('form.greater_than'),
    invalid_time: getName('form.invalid_time'),
    invalid_email: getName('form.invalid_email'),
    registration_id: getName('form.registration_id'),
    registration_title: getName('form.registration_title'),
    registration_subtitle: getName('form.registration_subtitle'),
    registration_error_title: getName('form.registration_error_title')
  },
  table: {
    search: getName('table.search'),
    expand_row: getName('table.expand_row'),
    collapse_row: getName('table.collapse_row'),
    edit: getName('table.edit'),
    post_pipeline: getName('table.post_pipeline'),
    delete: getName('table.delete'),
    rows_per_page: getName('table.rows_per_page'),
    displayed_rows: getName('table.displayed_rows'),
    clear_selected: getName('table.clear_selected'),
    selected: getName('table.selected'),
    responsible: getName('table.responsible')
  },
  registration_table: {
    title: getName('registration_table.title')
  },
  execution_loads: {
    title: getName('execution_loads.title'),
    menu_config_title: getName('execution_loads.menu_config_title'),
    cil_report: getName('execution_loads.cil_report'),
    alert: getName('execution_loads.alert'),
    alertPipeline: getName('execution_loads.alertPipeline'),
    alertParameter: getName('execution_loads.alertParameter')
  },
  user: {
    table_title: getName('user.table_title'),
    clone: getName('user.clone'),
    registration_title: getName('user.registration_title'),
    name_label: getName('user.name_label'),
    edit_message: getName('user.edit_message'),
    register_message: getName('user.register_message'),
    sendEmail: getName('user.sendEmail')
  },
  group: {
    tooltip: getName('group.tooltip'),
    registration_title: getName('group.registration_title'),
    info: getName('group.info'),
    table_title: getName('group.table_title'),
    name_titles: getName('group.name_titles'),
    name_title: getName('group.name_title'),
    load_group_name: getName('group.load_group_name'),
    cargo_group_description: getName('group.cargo_group_description'),
    info_2: getName('group.info_2'),
    edit_message: getName('group.edit_message'),
    register_message: getName('group.register_message'),
    cargo_identification: getName('group.cargo_identification')
  },
  sub_group: {
    tooltip: getName('sub_group.tooltip'),
    info: getName('sub_group.info'),
    description: getName('sub_group.description'),
    sub_group_name: getName('sub_group.sub_group_name'),
    edit_message: getName('sub_group.edit_message'),
    register_message: getName('sub_group.register_message'),
    add_sub_groups: getName('sub_group.add_sub_groups'),
    registration_title: getName('sub_group.registration_title'),
    name_title: getName('sub_group.name_title'),
    name_titles: getName('sub_group.name_titles'),
    empty_message: getName('sub_group.empty_message')
  },
  profile: {
    registration_title: getName('profile.registration_title'),
    edit_title: getName('profile.edit_title'),
    table_title: getName('profile.table_title'),
    view: getName('profile.view'),
    registration_success: getName('profile.registration_success'),
    edit_success: getName('profile.edit_success'),
    form_desc: getName('profile.form_desc'),
    roles: getName('profile.roles'),
    at_least_one_feature: getName('profile.at_least_one_feature'),
    name_label: getName('profile.name_label'),
    name_placeholder: getName('profile.name_placeholder'),
    desc_label: getName('profile.desc_label'),
    desc_placeholder: getName('profile.desc_placeholder'),
    profile: getName('profile.profile'),
    general: getName('profile.general'),
    pipeline_execution: getName('profile.pipeline_execution')
  },
  pipeline: {
    registration_title: getName('pipeline.registration_title'),
    edit_title: getName('pipeline.edit_title'),
    table_title: getName('pipeline.table_title'),
    form_desc: getName('pipeline.form_desc'),
    name_label: getName('pipeline.name_label'),
    add_pipeline: getName('pipeline.add_pipeline'),
    registration_success: getName('pipeline.registration_success'),
    edit_success: getName('pipeline.edit_success'),
    deleted_success: getName('pipeline.deleted_success'),
    btc_label: getName('pipeline.btc_label'),
    name_placeholder: getName('pipeline.name_placeholder'),
    file_frequency_label: getName('pipeline.file_frequency_label'),
    initial_hour_label: getName('pipeline.initial_hour_label'),
    final_hour_label: getName('pipeline.final_hour_label'),
    desc_label: getName('pipeline.desc_label'),
    desc_placeholder: getName('pipeline.desc_placeholder'),
    responsible_label: getName('pipeline.responsible_label'),
    responsible_placeholder: getName('pipeline.responsible_placeholder'),
    responsible_email_label: getName('pipeline.responsible_email_label'),
    responsible_email_placeholder: getName(
      'pipeline.responsible_email_placeholder'
    ),
    responsible2_label: getName('pipeline.responsible2_label'),
    responsible2_placeholder: getName('pipeline.responsible2_placeholder'),
    responsible2_email_label: getName('pipeline.responsible2_email_label'),
    responsible2_email_placeholder: getName(
      'pipeline.responsible_email_placeholder'
    ),
    send_email_label: getName('pipeline.send_email_label'),
    data_factory: getName('pipeline.data_factory'),
    data_factory_label: getName('pipeline.data_factory_label'),
    data_factory_table: {
      copy: getName('pipeline.data_factory_table.copy'),
      name: getName('pipeline.data_factory_table.name')
    },
    parameters: getName('pipeline.parameters'),
    new_parameter: getName('pipeline.new_parameter'),
    re_execute_message: getName('pipeline.re_execute_message'),
    map_pipeline_title: getName('pipeline.map_pipeline_title'),
    map_pipeline_desc: getName('pipeline.map_pipeline_desc')
  },
  allowed_values: {
    registration_title: getName('allowed_values.registration_title'),
    edit_title: getName('allowed_values.registration_title'),
    registration_success: getName('allowed_values.registration_success'),
    edit_success: getName('allowed_values.edit_success'),
    form_desc: getName('allowed_values.form_desc'),
    name_label: getName('allowed_values.name_label'),
    value_label: getName('allowed_values.value_label'),
    desc_label: getName('allowed_values.desc_label'),
    desc_placeholder: getName('allowed_values.desc_placeholder'),
    message_empty: getName('allowed_values.message_empty'),
    allowed_values_label_title: getName(
      'allowed_values.allowed_values_label_title'
    )
  },
  parameters: {
    registration_title: getName('parameters.registration_title'),
    edit_title: getName('parameters.edit_title'),
    registration_success: getName('parameters.registration_success'),
    edit_success: getName('parameters.edit_success'),
    form_desc: getName('parameters.form_desc'),
    name_label: getName('parameters.name_label'),
    pipeline_label: getName('parameters.pipeline_label'),
    order_label: getName('parameters.order_label'),
    type_label: getName('parameters.type_label'),
    desc_label: getName('parameters.desc_label'),
    desc_placeholder: getName('parameters.desc_placeholder'),
    length: getName('parameters.length'),
    required_label: getName('parameters.required_label'),
    interval_label: getName('parameters.interval_label'),
    multi_label: getName('parameters.multi_label'),
    allowed_values: getName('parameters.allowed_values'),
    add_allowed_values: getName('parameters.add_allowed_values'),
    additional_fields: getName('parameters.additional_fields'),
    variable_name_label: getName('parameters.variable_name_label'),
    data_factory_field_label: getName('parameters.data_factory_field_label'),
    data_source_label: getName('parameters.data_source_label'),
    required_fields_label: getName('parameters.required_fields_label'),
    conversion_routine_label: getName('parameters.conversion_routine_label'),
    readonly_label: getName('parameters.readonly_label'),
    show_dropdown_label: getName('parameters.show_dropdown_label'),
    download: getName('parameters.download')
  },
  log: {
    error_log: getName('log.error_log'),
    execution_log: getName('log.execution_log'),
    rerun_on_day: getName('log.rerun_on_day'),
    old_execution_log: getName('log.old_execution_log')
  },
  dialog: {
    delete: getName('dialog.delete'),
    delete_user: getName('dialog.delete_user'),
    delete_pipeline: getName('dialog.delete_pipeline'),
    delete_sub_group: getName('dialog.delete_sub_group')
  },
  management: {
    title: getName('management.title'),
    loads: getName('management.loads'),
    schedules: getName('management.schedules'),
    pre_id: getName('management.pre_id'),
    files: getName('management.files'),
    scheduled: getName('management.scheduled'),
    rerun: getName('management.rerun'),
    hour: getName('management.hour'),
    id_user: getName('management.id_user'),
    date_execution: getName('management.data_execution'),
    id_load: getName('management.id_load'),
    frequency: getName('management.frequency'),
    last_run_date: getName('management.last_run_date'),
    expected_date: getName('management.expected_date'),
    date_of_delay: getName('management.date_of_delay'),
    responsible_1: getName('management.responsible_1'),
    responsible_2: getName('management.responsible_2'),
    update_on: getName('management.update_on'),
    delayed_in: getName('management.delayed_in'),
    day: getName('management.day'),
    days: getName('management.days')
  },
  layoutFile: {
    title: getName('layoutFile.title'),
    max_length: getName('layoutFile.max_length'),
    column: getName('layoutFile.column'),
    fields: getName('layoutFile.fields'),
    choose: getName('layoutFile.choose'),
    register: getName('layoutFile.register'),
    register2: getName('layoutFile.register2'),
    operator: getName('layoutFile.operator'),
    functionality: getName('layoutFile.functionality'),
    confirm_field_success: getName('layoutFile.confirm_field_success')
  },

  execute: {
    execute_immediately: getName('execute.execute_immediately'),
    schedule_execution: getName('execute.schedule_execution'),
    execute_post_pipeline: getName('execute.execute_post_pipeline'),
    message_successful: getName('execute.message_successful'),
    message_successful_post_pipe: getName(
      'execute.message_successful_post_pipe'
    ),
    message_successful_immediately: getName(
      'execute.message_successful_immediately'
    ),
    message_immediately: getName('execute.message_immediately'),
    message_schedule: getName('execute.message_schedule'),
    message_post_pipeline: getName('execute.message_post_pipeline'),
    running: getName('execute.running'),
    info: getName('execute.info'),
    date: getName('execute.date'),
    choose: getName('execute.choose'),
    cancel_edit: getName('execute.cancel_edit'),
    run: getName('execute.run'),
    none: getName('execute.none')
  },
  status: {
    executing: getName('status.executing'),
    executed_successfully: getName('status.executed_successfully'),
    executed_with_error: getName('status.executed_with_error'),
    cancelled: getName('status.cancelled'),
    waiting: getName('status.waiting')
  },
  historic: {
    date_hour_initial_execution: getName(
      'historic.date_hour_initial_execution'
    ),
    date_hour_final_execution: getName('historic.date_hour_final_execution'),
    responsible: getName('historic.responsible'),
    label_name: getName('historic.label_name'),
    execution: getName('historic.execution')
  },

  file: {
    confirm_parameters: getName('file.confirm_parameters'),
    choose_file: getName('file.choose_file'),
    choose_file_for_scheduled_load: getName(
      'file.choose_file_for_scheduled_load'
    ),
    remove_file_from_schedule: getName('file.remove_file_from_schedule'),
    remove_file: getName('file.remove_file'),
    size: getName('file.size'),
    uploaded_file: getName('file.uploaded_file'),
    file_uploaded_to_the_scheduled_load: getName(
      'file.file_uploaded_to_the_scheduled_load'
    ),
    no_file_for_the_load: getName('file.no_file_for_the_load')
  }
}
