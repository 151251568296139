import { ThemeProvider } from '@mui/material/styles'
import { GlobalStyles } from '@mui/styled-engine'
import theme from 'shared/styles/theme'
import AppRoutes from 'routes'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AddSubGroupProvider } from 'shared/contexts/AddSubGroupContext'
import { AddPipelinesProvider } from 'shared/contexts/pipelines/AddPipelineContext'
import { UserAddPipelinesProvider } from 'shared/contexts/users/UserAddPipelineContext'
import { MsalProvider } from '@azure/msal-react'
import { msalInstance } from 'shared/configs/authConfig'
import { AuthProvider } from 'shared/hooks/auth/useAuth'
import SuspenseApp from 'shared/components/SuspenseApp'
import globalStyle from 'shared/styles/globalStyle'

const queryClient = new QueryClient()

function App() {
  return (
    <MsalProvider instance={msalInstance}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <ThemeProvider theme={theme}>
            <UserAddPipelinesProvider initialState={[]}>
              <AddPipelinesProvider initialState={[]}>
                <AddSubGroupProvider initialState={[]}>
                  <GlobalStyles styles={globalStyle} />
                  <SuspenseApp>
                    <AppRoutes />
                  </SuspenseApp>
                </AddSubGroupProvider>
              </AddPipelinesProvider>
            </UserAddPipelinesProvider>
          </ThemeProvider>
        </AuthProvider>
      </QueryClientProvider>
    </MsalProvider>
  )
}

export default App
