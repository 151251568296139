/* eslint-disable no-debugger */
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useState
} from 'react'
import { useMsalAuthentication, useMsal, useAccount } from '@azure/msal-react'
import {
  AccountInfo,
  InteractionType,
  InteractionStatus,
  InteractionRequiredAuthError,
  AuthenticationResult
} from '@azure/msal-browser'
import { Paged } from 'interfaces/PagedObject'
import { PERMISSIONS } from 'shared/constants/permissions'
import api from 'shared/configs/api'
import authApi from 'shared/configs/authApi'
import { useQuery } from 'react-query'
import { RootObject } from 'interfaces/AuthLogin'
import { getToken } from 'shared/services/auth'
import jwt_decode from 'jwt-decode'
import { ContentGroup } from 'shared/services/users/types'
import { getProfileLoadGroups } from 'shared/services/users'

interface IAuthContextData {
  signOut: () => void
  account: AccountInfo | null
  userGroups?: ContentGroup[]
  checkPermissions(permissions: string[]): boolean
  isLoading: boolean
}
type decodeArray = {
  roles: string[]
}
const AuthContext = createContext<IAuthContextData>({} as IAuthContextData)

const AuthProvider: FC = ({ children }) => {
  useMsalAuthentication(InteractionType.Redirect)

  const { instance, accounts, inProgress } = useMsal()
  const account = useAccount(accounts[0])
  const [atsResponse, setAtsResponse] = useState<AuthenticationResult>()
  const [token, setToken] = useState<string>('')
  const [decodeJwt, setDecodeJwt] = useState<decodeArray>({ roles: [] })
  const [tokenJwt, setTokenJwt] = useState<RootObject>()
  const [userGroups, setUserGroups] = useState<ContentGroup[] | undefined>([])
  //TO DO: colocar no interceptor a validação do token

  const checkPermissions = useCallback(
    (permissions: string[]): boolean => {
      if (permissions[0] === 'register') {
        return true
      }
      return permissions.some((permission) =>
        decodeJwt.roles
          .map((role) => role.toLowerCase())
          .includes(permission.toLowerCase())
      )
    },
    [decodeJwt]
  )

  const acquireToken = useCallback(
    async (acc: AccountInfo) => {
      const request = {
        scopes: [`${process.env.REACT_APP_AZURE_AD_CLIENT_ID}/.default`],
        account: acc
      }

      try {
        return await instance.acquireTokenSilent(request)
      } catch (e) {
        if (e instanceof InteractionRequiredAuthError) {
          instance.acquireTokenRedirect(request)
        }
      }

      return null
    },
    [instance]
  )

  const signOut = useCallback(() => instance.logout(), [instance])

  useEffect(() => {
    if (!atsResponse && account && inProgress === InteractionStatus.None) {
      acquireToken(account).then((response) => {
        if (response) {
          setToken(() => response.accessToken)
          setAtsResponse(response)
        }
      })
    }
  }, [account, acquireToken, atsResponse, inProgress, instance])

  useEffect(() => {
    api.interceptors.request.use(
      async (config) => {
        if (account) {
          if (tokenJwt) {
            return Object.assign(config, {
              headers: {
                Authorization: `Bearer ${tokenJwt.accessToken}`
              }
            })
          }
        }

        return config
      },
      (error) => Promise.reject(error)
    )
    authApi.interceptors.request.use(
      async (config) => {
        if (account) {
          if (tokenJwt) {
            return Object.assign(config, {
              headers: {
                Authorization: `Bearer ${tokenJwt.accessToken}`
              }
            })
          }
        }

        return config
      },
      (error) => Promise.reject(error)
    )
  }, [tokenJwt])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const queryGetToken = useQuery({
    queryKey: ['getToken', token],
    queryFn: async () => {
      const data = await getToken(token)
      return data
    },
    onSuccess: (data: RootObject) => {
      setTokenJwt(data)
    },
    onError: () => {
      //Se não adquirir o segundo TOKEN, deslogar!
      signOut()
    },
    enabled: token !== '',
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    refetchInterval: false
  })

  const { isFetching: loadingGroups } = useQuery({
    queryKey: ['queryGetGroups', tokenJwt],
    queryFn: async () => {
      const data = await getProfileLoadGroups()
      return data
    },
    onSuccess: (data: Paged<ContentGroup>) => {
      if (data && data?.content.length > 0) {
        const filteredGroup = data.content.filter((item) => item.active)
        setUserGroups(() => filteredGroup)
      }
    },
    enabled:
      tokenJwt !== undefined &&
      checkPermissions([
        PERMISSIONS.GENERAL_ADMIN,
        PERMISSIONS.PIPELINE_RUN.READ
      ]),
    refetchInterval: false,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    if (tokenJwt) {
      setDecodeJwt(jwt_decode(tokenJwt.accessToken))
    }
  }, [tokenJwt])

  return (
    <AuthContext.Provider
      value={{
        signOut,
        account,
        userGroups,
        checkPermissions,
        isLoading: queryGetToken.isFetching || loadingGroups
      }}
    >
      {atsResponse ? children : null}
    </AuthContext.Provider>
  )
}

function useAuth(): IAuthContextData {
  const context = useContext(AuthContext)

  if (!context) {
    throw new Error(`useAuth must be used within a AuthProvider`)
  }

  return context
}

export { AuthProvider, useAuth }
