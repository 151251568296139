import { Box, Divider, Grid, Typography } from '@mui/material'
import { colors } from 'shared/styles/colors'

import { ITopContainerProps } from './types'

const TopContainer = ({ title }: ITopContainerProps) => {
  return (
    <>
      <Grid
        container
        direction="row"
        alignItems="center"
        padding={2}
        paddingTop={3}
      >
        <Grid item xs={0}>
          <Box
            sx={{
              background: colors.main01,
              borderRadius: '7px',
              width: '1rem',
              height: '2rem',
              color: 'transparent',
              marginRight: '1rem'
            }}
          />
        </Grid>
        <Grid item xs={11}>
          <Typography
            component="h1"
            variant="h4"
            fontSize="2rem"
            lineHeight="1"
            fontFamily="Poppins"
            color={colors.secondary03}
          >
            {title}
          </Typography>
        </Grid>
      </Grid>
      <Box paddingY={1}>
        <Divider />
      </Box>
    </>
  )
}

export default TopContainer
