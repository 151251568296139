import { TFunction } from 'i18next'
import commonLocale from 'shared/i18n/locales/common'

import { IRoute, RoutePath } from './types'
import { PERMISSIONS } from 'shared/constants/permissions'
import * as Pages from 'pages'
import IconSvg from 'shared/components/Icon'
import IconHome from 'assets/home.png'
import IconMenu from 'assets/box-add.png'
import IconSearch from 'assets/box-search.png'

export const getRoutes = (t: TFunction): Array<IRoute> => [
  {
    path: '/',
    component: <Pages.default.Home />,
    meta: {
      title: 'Home'
    },
    icon: <IconSvg img={IconHome} />
  },
  {
    path: `/sigin`,
    component: <Pages.default.Home />,
    meta: {
      title: 'Home',
      hidden: true
    },
    icon: <IconSvg img={IconHome} />
  },
  {
    path: '*',
    component: <Pages.default.Home />,
    meta: {
      title: 'Home',
      hidden: true
    },
    icon: <IconSvg img={IconHome} />
  },
  {
    path: RoutePath.ProfileCreate,
    component: <Pages.default.ProfileCreate />,
    meta: {
      title: t(commonLocale.profile.registration_title),
      profilesAuthorized: [
        PERMISSIONS.ACCESS_PROFILE.CREATE,
        PERMISSIONS.GENERAL_ADMIN
      ]
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.UserRegistration,
    component: <Pages.default.UserRegistration />,
    meta: {
      title: t(commonLocale.user.registration_title),
      profilesAuthorized: [PERMISSIONS.USER.CREATE, PERMISSIONS.GENERAL_ADMIN]
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.SubgroupCreate,
    component: <Pages.default.RegistrationSubGroup />,
    meta: {
      title: t(commonLocale.sub_group.registration_title),
      // title: 'Cadastro Sub Grupos',
      profilesAuthorized: [
        PERMISSIONS.SUB_GROUP.CREATE,
        PERMISSIONS.GENERAL_ADMIN
      ]
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.GroupRegistration,
    component: <Pages.default.RegistrationGroup />,
    meta: {
      title: t(commonLocale.group.registration_title),
      profilesAuthorized: [PERMISSIONS.GROUP.CREATE, PERMISSIONS.GENERAL_ADMIN]
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.AllowedValueCreate,
    component: <Pages.default.AllowedValueCreate />,
    meta: {
      title: t(commonLocale.allowed_values.registration_title),
      profilesAuthorized: [
        PERMISSIONS.ALLOWED_VALUE.CREATE,
        PERMISSIONS.GENERAL_ADMIN
      ]
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.ParameterCreate,
    component: <Pages.default.ParameterCreate />,
    meta: {
      title: t(commonLocale.parameters.registration_title),
      profilesAuthorized: [
        PERMISSIONS.GENERAL_ADMIN,
        PERMISSIONS.PIPELINE_PARAMETER.CREATE
      ]
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.PipelineMap,
    component: <Pages.default.MapPipeline />,
    meta: {
      title: t(commonLocale.pipeline.map_pipeline_title),
      hidden: false,
      profilesAuthorized: [
        PERMISSIONS.PIPELINE.CREATE,
        PERMISSIONS.GENERAL_ADMIN
      ]
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.Management,
    component: <Pages.default.Management />,
    meta: {
      title: t(commonLocale.management.title),
      profilesAuthorized: [PERMISSIONS.GENERAL_ADMIN]
    },
    icon: <IconSvg img={IconSearch} />
  },
  {
    path: RoutePath.RegistrationTable,
    component: <Pages.default.RegistrationTable />,
    meta: {
      title: t(commonLocale.registration_table.title),
      profilesAuthorized: [
        PERMISSIONS.PIPELINE.READ,
        PERMISSIONS.USER.READ,
        PERMISSIONS.GROUP.READ,
        PERMISSIONS.SUB_GROUP.READ,
        PERMISSIONS.PIPELINE_PARAMETER.READ,
        PERMISSIONS.GENERAL_ADMIN
      ]
    },
    icon: <IconSvg img={IconSearch} />
  },
  {
    path: RoutePath.UserRegistrationEmail,
    component: <Pages.default.UserEdit />,
    meta: {
      title: t(commonLocale.user.registration_title),
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.UserRegistrationClone,
    component: <Pages.default.UserClone />,
    meta: {
      title: t(commonLocale.user.registration_title),
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.ProfileEdit,
    component: <Pages.default.ProfileEdit />,
    meta: {
      title: t(commonLocale.profile.edit_title, { name: '...' }),
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.GroupRegistrationEdit,
    component: <Pages.default.RegistrationGroup />,
    meta: {
      title: 'Cadastro Grupos',
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.SubgroupEdit,
    component: <Pages.default.RegistrationSubGroup />,
    meta: {
      title: 'Cadastro Sub Grupos',
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.PipelineEdit,
    component: <Pages.default.PipelineEdit />,
    meta: {
      title: t(commonLocale.pipeline.edit_title, { name: '...' }),
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },

  {
    path: RoutePath.PipelineParameterCreate,
    component: <Pages.default.PipelineParameterCreate />,
    meta: {
      title: t(commonLocale.parameters.registration_title),
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },

  {
    path: RoutePath.ParameterEdit,
    component: <Pages.default.ParameterEdit />,
    meta: {
      title: t(commonLocale.parameters.edit_title, { name: '...' }),
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.AllowedValueEdit,
    component: <Pages.default.AllowedValueEdit />,
    meta: {
      title: t(commonLocale.allowed_values.edit_title, { name: '...' }),
      hidden: true
    },
    icon: <IconSvg img={IconMenu} />
  },
  {
    path: RoutePath.FileLayoutEdit,
    component: <Pages.default.FileLayoutEdit />,
    meta: {
      title: 'Editar Layout de Arquivo',
      hidden: true
    }
  }
]
