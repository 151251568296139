import authApi from 'shared/configs/authApi'
import { RootObject } from 'interfaces/AuthLogin'

const endpointUrl = 'Auth/login'

export const getToken = async (params) => {
  const url = `${endpointUrl}/`
  const { data } = await authApi.post<RootObject>(url, { token: params })
  return data
}
