import { Interpolation } from '@emotion/react'
import { ThemeOptions } from '@mui/material'
import { colors } from 'shared/styles/colors'

const globalStyle = {
  html: {
    fontFamily: 'Roboto, sans-serif',
    WebkitFontSmoothing: 'antialiased'
  },
  body: {
    fontSize: '1rem',
    background: colors.neutralSecondary
  },
  boxSizing: 'border-box',
  margin: 0,
  padding: 0
} as Interpolation<ThemeOptions>

export default globalStyle
