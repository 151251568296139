import authApi from 'shared/configs/authApi'
import api from 'shared/configs/api'
import { Content, User, RootObject, RouteStateUserApi } from 'interfaces/Users'
import { Paged } from 'interfaces/PagedObject'
import { IRequestListResponse } from 'interfaces/RequestListResponse'
import { ContentGroup, ContentSubGroup, ContentPipeline } from './types'

const endpointUrl = 'Users'

export const getUsers = async (name) => {
  const { data } = await authApi.get<Content[]>(`adusers?Name=${name}`)
  return data
}

export const postUserAuth = async (user) => {
  const { data } = await authApi.post<User>('users', user)

  return data
}

export const postUser = async (user) => {
  const { data } = await api.post<User>('Users', user)

  return data
}

export const getPagedUser = async (name?: string, number = 1, limit = 5) => {
  const { data } = await authApi.get<Paged<RootObject>>('users', {
    params: { active: true, name, number, limit, orderBy: 'name', asc: true }
  })

  return data
}

export const getPagedUserByEmail = async (
  email?: string,
  number = 1,
  limit = 5
) => {
  const { data } = await authApi.get<Paged<RootObject>>('users', {
    params: { active: true, email, number, limit, orderBy: 'name', asc: true }
  })

  return data
}

export const getGroups = async (email: string) => {
  const url = `${endpointUrl}/${email}/loadgroups`
  const { data } = await api.get<Paged<ContentGroup>>(url)
  return data
}

export const getProfileLoadGroups = async () => {
  const url = `Profile/loadgroups`
  const { data } = await api.get<Paged<ContentGroup>>(url)
  return data
}

export const getSubGroups = async (email: string) => {
  const url = `${endpointUrl}/${email}/subgroups`
  const { data } = await api.get<Paged<ContentSubGroup>>(url)
  return data
}
export const getPipeline = async (email: string | undefined) => {
  const url = `${endpointUrl}/${email}/pipelines?limit=${50}`
  const { data } = await api.get<Paged<ContentPipeline>>(url)
  return data
}

export const getUserApi = async (email) => {
  const url = `${endpointUrl}/${email}`

  const { data } = await api.get<RouteStateUserApi>(url)

  return data
}

export const putUserAuth = async (email, user) => {
  const { data } = await authApi.put<IRequestListResponse<null>>(
    `users/${email}`,
    user
  )

  return data
}

export const putUserApi = async (email, params) => {
  const url = `${endpointUrl}/${email}`
  const { data } = await api.put<IRequestListResponse<null>>(url, params)
  return data
}

export const toggleActiveUser = async (email) => {
  const url = `${endpointUrl}/${email}/toggleactive`
  const { data } = await authApi.post<IRequestListResponse<null>>(url)
  return data
}
