import { createTheme, ThemeOptions } from '@mui/material/styles'
import { TypographyOptions } from '@mui/material/styles/createTypography'
import { colors } from './colors'

declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    formDescription: true
    fieldsetTitle: true
    subTableTitle: true
    checkboxGroupLabel: true
  }
}

interface ExtendedTypographyOptions extends TypographyOptions {
  formDescription: React.CSSProperties
  fieldsetTitle: React.CSSProperties
  subTableTitle: React.CSSProperties
  checkboxGroupLabel: React.CSSProperties
}

const theme = createTheme({
  palette: {
    primary: {
      main: colors.secondary03
    },
    background: {
      default: colors.white
    },
    secondary: {
      main: colors.secondary
    },
    error: {
      main: colors.red
    }
  },
  typography: {
    formDescription: {
      fontSize: '1rem',
      lineHeight: '1.1875',
      color: colors.black
    },
    fieldsetTitle: {
      fontWeight: 600,
      fontSize: '1rem',
      lineHeight: '1.1875',
      color: colors.black
    },
    subTableTitle: {
      fontWeight: 500,
      fontSize: '1.125rem',
      color: colors.black
    },
    checkboxGroupLabel: {
      fontWeight: 500,
      fontSize: '1.125rem',
      color: colors.black
    }
  } as ExtendedTypographyOptions
} as ThemeOptions)

export default theme
