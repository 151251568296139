import { Suspense } from 'react'
import { Box, CircularProgress } from '@mui/material'

const SuspenseApp: React.FC = ({ children }) => {
  return (
    <Suspense
      fallback={
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw'
          }}
        >
          <CircularProgress size="4rem" />
        </Box>
      }
    >
      {children}
    </Suspense>
  )
}

export default SuspenseApp
