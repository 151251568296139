type LogoHeinekenProps = {
  width?: number
  height?: number
  title: string
}

export const LogoHeineken = ({
  width = 1200,
  height = 800,
  title
}: LogoHeinekenProps) => {
  // this logo uses 3:2 aspect ration
  // it's default size is 1200 x 800 (w x h)
  // for small sizes, I recommend 300 x 200
  // for medium sizes, go with 600 x 400
  // large size is it's default

  return (
    <svg
      width={width}
      height={height}
      viewBox="-14.27139 -5.531125 123.68538 33.18675"
    >
      <title id={title}>Heineken Logo</title>
      <defs>
        <clipPath clipPathUnits="userSpaceOnUse" id="a">
          <path d="M211.725 465.2271h78.716v63.576h-78.716z" />
        </clipPath>
      </defs>
      <g
        clipPath="url(#a)"
        transform="matrix(1.25 0 0 -1.25 -265.1174 637.9608)"
      >
        <path
          d="M288.208 502.2631v-.377s-.409-.163-.409-.643l.002-8.059c0-.283-.231-.5151-.514-.5151h-1.584l-3.485 5.4981s.073-.455.073-1.028v-4.47h-1.93v9.594h1.251c.638 0 1.103-.2631 1.438-.79.284-.446 2.917-4.592 2.917-4.592s-.094.282-.094 1.039v4.343zm-37.068 0v-9.079c0-.283-.232-.515-.515-.515h-1.584l-3.485 5.498s.072-.455.072-1.028v-4.47h-1.929v9.594h1.25c.639 0 1.104-.2631 1.438-.79.284-.446 2.918-4.592 2.918-4.592s-.094.282-.094 1.039v4.343zm-23.876 0h-2.58v-3.677h-2.257v3.677h-2.996v-.377s.409-.154.409-.643v-8.574h2.587v4.163h2.257v-4.163h2.58zm8.357 0h-5.75v-8.736c0-.499.474-.858.97-.858h4.78c.283 0 .516.233.516.516v1.238h-3.297c-.215 0-.388.177-.388.391v2.019h2.939v1.753h-2.939v1.924h3.685v1.236c0 .285-.233.517-.516.517m5.447 0h-2.581v-9.079c0-.283.234-.515.517-.515h2.064zm36.59 0h-5.749v-8.736c0-.478.472-.858.968-.858h4.781c.283 0 .516.233.516.516v1.238h-3.298c-.214 0-.387.177-.387.391v2.019h2.939v1.753h-2.939v1.924h3.685v1.236c0 .285-.233.517-.516.517m-18.162 0h-5.748v-8.736c0-.478.472-.858.969-.858h4.779c.284 0 .517.233.517.516v1.238h-3.298c-.214 0-.387.177-.387.391v2.019h2.939v1.753h-2.939v1.924h3.685v1.236c0 .285-.233.517-.517.517m10.92 0h-1.862c-.448 0-.792-.113-1.147-.523-.356-.4101-2.629-3.03-2.629-3.03v3.553h-2.581v-9.079c0-.283.233-.5151.516-.5151h2.065v4.3511s1.935-3.027 2.273-3.557c.341-.532.678-.794 1.452-.794h2.12l-3.621 5.661"
          fill="#265a38"
        />
        <path
          d="M214.622 498.7701c-.13-.095-.299.029-.249.178.071.213 1.367 4.209 1.367 4.209l-3.578 2.5999c-.129.0951-.062.292.095.2911.225-.001 4.423 0 4.423 0s1.299 3.9929 1.366 4.207c.048.15.259.153.308-.001.068-.214 1.367-4.206 1.367-4.206s4.198-.001 4.423 0c.157.0009.224-.196.094-.2911l-2.134-1.5509h-2.526l-.78 2.3999-.78-2.3999h-2.526l2.044-1.484-.781-2.401s-2.003-1.457-2.133-1.551"
          fill="#ed1c24"
        />
      </g>
    </svg>
  )
}

export default LogoHeineken
