import React from 'react'
import { createContext } from 'react'
import { Content } from 'interfaces/Pipelines'
import { IUserAddPipelinesContext } from './types'
import { RouteState } from 'interfaces/Users'
import { GridSelectionModel } from '@mui/x-data-grid'
export const UserAddPipelineContext = createContext<IUserAddPipelinesContext>(
  {} as IUserAddPipelinesContext
)

export const UserAddPipelinesProvider = ({ initialState, children }) => {
  const [pipelineArray, setPipelineArray] =
    React.useState<Content[]>(initialState)
  const [pipelineOldArray, setPipelineOldArray] =
    React.useState<Content[]>(initialState)
  const [pipelineCheck, setPipelineCheck] = React.useState<GridSelectionModel>(
    []
  )

  const [dataInput, setDataInput] = React.useState<RouteState>({
    name: '',
    email: '',
    receiveEmailPipeline: false,
    idAccessProfile: '',
    nameProfile: '',
    pipelines: []
  })

  const onChange = (row: Content[]) => {
    setPipelineArray(row)
  }

  const onChangeOldPipelineArray = (row: Content[]) => {
    setPipelineOldArray(row)
  }
  const onChangePipelineCheck = (row: GridSelectionModel) => {
    setPipelineCheck(row)
  }

  const onChangeDelete = (id: string) => {
    setPipelineArray((item) => [
      ...item.filter((currentRow) => currentRow.id !== id)
    ])
  }

  const onChangeDataInput = (row: RouteState) => {
    setDataInput(row)
  }

  return (
    <UserAddPipelineContext.Provider
      value={{
        pipelineArray,
        dataInput,
        pipelineCheck,
        pipelineOldArray,
        onChange,
        onChangeDelete,
        onChangeDataInput,
        onChangeOldPipelineArray,
        onChangePipelineCheck
      }}
    >
      {children}
    </UserAddPipelineContext.Provider>
  )
}
