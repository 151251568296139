import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  IconButton,
  Grid
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { MenuOutlined } from '@mui/icons-material'
import i18n from 'shared/i18n'
import { LogoHeineken } from 'assets'
import IconSvg from 'shared/components/Icon'
import IconUser from 'assets/usuario.png'
import IconTranslate from 'assets/translate.png'
import IconLogout from 'assets/logout.png'
import { colors } from 'shared/styles/colors'
import { FormControl, MenuItem, Select } from '@mui/material'
import commonLocale from 'shared/i18n/locales/common'
import { useEffect, useState } from 'react'
interface HeaderProps {
  onClickMenuButton: () => void
  onClickLogout: () => void
  username?: string
}

const arr = [
  {
    value: 'pt',
    label: 'PT-BR'
  },
  { value: 'en', label: 'ENGLISH' }
]
export const Header = ({
  onClickMenuButton,
  username,
  onClickLogout
}: HeaderProps) => {
  const [valueTranslate, setValueTranslate] = useState<string>(arr[0].value)
  const { t } = useTranslation(['common'])
  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng)
  }

  useEffect(() => {
    changeLanguage(valueTranslate)
  }, [valueTranslate])

  return (
    <AppBar position="fixed">
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: colors.neutralSecondary,
          height: '70px'
        }}
      >
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          onClick={onClickMenuButton}
          sx={{
            color: '#205527'
          }}
        >
          <MenuOutlined />
        </IconButton>

        <LogoHeineken title="AppBarLogo" width={300} height={200} />

        <Box sx={{}}>
          <Grid container direction="row" alignItems="center" spacing={3}>
            <Grid item xs={1}>
              <IconSvg img={IconUser} />
            </Grid>
            <Grid item xs={4}>
              <Typography
                sx={{
                  fontWeight: 400,
                  color: '#205527'
                }}
              >
                {username}
              </Typography>
            </Grid>
            <Grid item xs={1}>
              <IconSvg img={IconTranslate} />
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth>
                {/* <InputLabel id={`${id}-label`}>{name}</InputLabel> */}
                <Select
                  key={'select-translate'}
                  id={'select-translate-d'}
                  labelId={`-label`}
                  value={valueTranslate}
                  onChange={(event) => setValueTranslate(event.target.value)}
                >
                  {arr.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.value}>
                        {item.label}
                      </MenuItem>
                    )
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={1}>
              <IconButton
                onClick={onClickLogout}
                aria-label="Fazer o logout"
                sx={{
                  display: 'flex',
                  alignItems: 'initial'
                }}
              >
                {/* <ExitToAppOutlined sx={{ color: '#205527', mr: 0.5 }} /> */}
                <Grid item xs={10}>
                  <IconSvg img={IconLogout} />
                </Grid>

                <Typography
                  sx={{
                    fontWeight: 400,
                    color: '#205527'
                  }}
                >
                  {t(commonLocale.exit)}
                </Typography>
              </IconButton>
            </Grid>
          </Grid>
        </Box>
      </Toolbar>
    </AppBar>
  )
}
