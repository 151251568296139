import { Link } from 'react-router-dom'

import {
  Box,
  ClickAwayListener,
  Divider,
  Drawer,
  useTheme,
  List
} from '@mui/material'
import Loading from 'shared/components/Loading'
import MenuItem from './menuItem'
import { LogoHeineken } from 'assets'
import { useAuth } from 'shared/hooks/auth/useAuth'
import { IRoute } from 'routes/types'

interface MenuProps {
  routes: Array<IRoute>
  openDrawer: boolean
  onCloseDrawer: () => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleDrawer?: any
}

export const Menu = ({
  routes,
  openDrawer,
  onCloseDrawer,
  toggleDrawer
}: MenuProps) => {
  let openDrawerButtonClicked = false
  const theme = useTheme()
  const { isLoading } = useAuth()
  const handleClickAway = () => {
    if (openDrawer && openDrawerButtonClicked) {
      openDrawerButtonClicked = false
      return onCloseDrawer()
    }
    openDrawerButtonClicked = true
    return null
  }

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Drawer
        anchor="left"
        open={openDrawer}
        aria-label="drawer-menu"
        variant="persistent"
        onClose={() => onCloseDrawer()}
        sx={{
          '& .MuiPaper-root': {
            boxShadow:
              '0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12), 0px 2px 4px rgba(0, 0, 0, 0.2)'
          }
        }}
      >
        <Box
          width={theme.spacing(28)}
          height="100%"
          display="flex"
          flexDirection="column"
        >
          <Box
            width="100%"
            height={theme.spacing(8)}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <LogoHeineken title="SideBarLogo" />
          </Box>
          <Divider />
          <Loading isLoading={isLoading}>
            <Box flex={1} role="menu">
              <List
                component="nav"
                sx={{
                  marginTop: '10px'
                }}
              >
                {routes
                  .filter((router) => !router?.meta?.hidden)
                  .map((route) => {
                    return (
                      <MenuItem
                        key={route.path}
                        component={Link}
                        route={route}
                        toggleDrawer={toggleDrawer}
                      />
                    )
                  })}
              </List>
            </Box>
          </Loading>
        </Box>
      </Drawer>
    </ClickAwayListener>
  )
}
