export const PERMISSIONS = {
  GENERAL_ADMIN: 'GENERAL_ADMIN',
  USER: {
    CREATE: 'USER_CREATE',
    DELETE: 'USER_DELETE',
    UPDATE: 'USER_UPDATE',
    READ: 'USER_READ'
  },
  ACCESS_PROFILE: {
    CREATE: 'ACCESS_PROFILE_CREATE',
    DELETE: 'ACCESS_PROFILE_DELETE',
    UPDATE: 'ACCESS_PROFILE_UPDATE',
    READ: 'ACCESS_PROFILE_READ'
  },
  GROUP: {
    CREATE: 'GROUP_CREATE',
    DELETE: 'GROUP_DELETE',
    UPDATE: 'GROUP_UPDATE',
    READ: 'GROUP_READ'
  },
  SUB_GROUP: {
    CREATE: 'SUBGROUP_CREATE',
    DELETE: 'SUBGROUP_DELETE',
    UPDATE: 'SUBGROUP_UPDATE',
    READ: 'SUBGROUP_READ'
  },
  PIPELINE: {
    CREATE: 'PIPELINE_CREATE',
    DELETE: 'PIPELINE_DELETE',
    UPDATE: 'PIPELINE_UPDATE',
    READ: 'PIPELINE_READ'
  },
  ALLOWED_VALUE: {
    CREATE: 'ALLOWED_VALUE_CREATE',
    DELETE: 'ALLOWED_VALUE_DELETE',
    UPDATE: 'ALLOWED_VALUE_UPDATE',
    READ: 'ALLOWED_VALUE_READ'
  },
  PIPELINE_RUN: {
    CREATE: 'PIPELINE_RUN_CREATE',
    UPDATE: 'PIPELINE_RUN_UPDATE',
    READ: 'PIPELINE_RUN_READ'
  },
  PIPELINE_PARAMETER: {
    CREATE: 'PIPELINE_PARAMETER_CREATE',
    DELETE: 'PIPELINE_PARAMETER_DELETE',
    UPDATE: 'PIPELINE_PARAMETER_UPDATE',
    READ: 'PIPELINE_PARAMETER_READ'
  }
}
