export const colors = {
  primary: '#3980FF',
  black: '#212121',
  lightBlack: '#606060',
  white: '#FFFFFF',
  lightGray: 'rgba(0, 0, 0, 0.38)',
  darkGray: 'rgba(0, 0, 0, 0.54)',
  darkestGray: 'rgba(0, 0, 0, 0.84)',
  overlayPrimary: 'rgba(30, 150, 250, 0.08)',
  overlayHover: 'rgba(0, 0, 0, 0.08)',
  secondary03: '#288154',
  main01: '#A7E833',
  main02: '#5ACD8B',
  secondary: '#f73378',
  red: '#FF1040',
  neutralSecondary: '#EEF0EB'
}
